export const tutorialLocal = {
  namespaced: true,
  state: {
    refElement: null,
  },
  getters: {
    getRefElement: state => state.refElement,
  },
  actions: {
    setRefElement({commit}, payload) {
      commit('setRefElement', payload)
    },
  },
  mutations: {
    setRefElement(store, payload) {
      store.refElement = payload
    },
  }
}